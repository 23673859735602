import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../Pages/Home";
import Footer from "../layout/Footer/Footer";
import Navbar from "../layout/NavBar/NavBar";
export function MyRoutes() {
  return (
    <>
      <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      <Footer />
      </>
  );
}
