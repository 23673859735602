import React from 'react'
import { Roadmap, RoadmapMobile } from '../../../Assets'
import { RoadmapContainer } from './style'
import Heading from "../../../Components/Headings"

const index = () => {
  return (
    <RoadmapContainer>
          <div className="bg-lines">
      <span className="line-1"></span>
      <span className="line-2"></span>
      <span className="line-3"></span>
      <span className="line-4"></span>
      <span className="line-5"></span>
      </div>
        <img src={Roadmap} alt="" className='desktop-image' />
        <Heading text={'Roadmap.'} size={'40px'} color={'#fff'}/>
        <img src={RoadmapMobile} alt="" className='mobile-image' />
    </RoadmapContainer>
  )
}

export default index