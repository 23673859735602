import React from 'react'
import { TradingContainer } from './style'
import Heading from "../../../Components/Headings"

const index = () => {
  return (
    <TradingContainer>
          <div className="bg-lines">
      <span className="line-1"></span>
      <span className="line-2"></span>
      <span className="line-3"></span>
      <span className="line-4"></span>
      <span className="line-5"></span>
      </div>
        <Heading text={'Join WARRIOR Now.'} size={'40px'} color={'#fff'}/>
        <div className="cards-container">
            <div className="card">
                 <h2>1</h2>
                <h5>Convenience</h5>
                <h6>Trade NFTs with ease and convenience on our platform.</h6>
            </div>
            <div className="card">
               <h2>2</h2>
                <h5>Flexibility</h5>
                <h6>Enjoy a flexible trading experience with multiple token and chain support.</h6>
            </div>
            <div className="card">
                <h2>3</h2>
                <h5>Security</h5>
                <h6>Benefit from enhanced security and protection for your assets and data.</h6>
            </div>
        </div>
    </TradingContainer>
  )
}

export default index