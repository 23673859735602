import React from 'react'
import Header from './Header'
import Trading from './Trading'
import Join from './Join'
import Optimisation from './Optimisation'
import Mail from "./Mail"
import LatestNews from "./LatestNews"
import Popular from "./Popular"
import Exclusive from "./Exclusive"
import Roadmap  from './Roadmap'
import "swiper/swiper-bundle.css";
import 'swiper/css';
import 'swiper/css/free-mode';
const index = () => {
  return (
    <>
   <Header />
   <Trading />
   <Popular />
   <Exclusive />
   <Optimisation />
   <Join />
   <Mail/>
   <Roadmap />
   <LatestNews />
   </>
  )
}

export default index