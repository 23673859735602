import styled from "styled-components";
import { HeaderBG } from '../../../Assets'
export const HeaderContainer = styled.div`
background: url(${HeaderBG});
padding:140px 120px 60px 60px;
display: flex;
width: 100%;
justify-content: space-between;
gap: 100px;
background-size: cover;
background-repeat: no-repeat;
background-position: center;

@media screen and (max-width:768px) {
    padding: 120px 30px 60px 30px;
    flex-direction: column;
}

.first-part{
    width: 50%;
    @media screen and (max-width:768px){
        width: 100%;
        text-align: center;
    }
    h1{
        position: relative;
        font-size: 72px;
        font-family: 'Russo One';

        @media screen and (max-width:768px) {
                font-size: 52px;
            }
        span{
        background: linear-gradient(128deg, #FFA900 23.44%, #FFEDAE 71.33%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
        &::after{
            content: 'WARRIOR';
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(128deg, #FFA900 23.44%, #FFEDAE 71.33%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            top: 0;
            left: 0;
            opacity: 0.4;
            filter: blur(9px);
            z-index: 1;
        }

        }
        &::after{
            content: 'Welcome to';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.4;
            filter: blur(9px);
            z-index: 1;
        }
    }
    p{
        margin-top: 24px;
        margin-bottom: 40px;
        width: 90%;

        @media screen and (max-width:768px) {
                width: 100%;
            }
    }
    .stat-card{
        position: relative;
        width: 90%;
        display: flex;  
        padding: 16px 20px;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        border-radius: 16px;
        z-index: 1;
        border-radius: 16px;
        border: 1px solid  #FFA900;
        background: linear-gradient(137deg, rgba(255, 255, 255, 0.13) -6.35%, rgba(255, 255, 255, 0.06) 105.62%);
        backdrop-filter: blur(37.22630310058594px);
        -webkit-backdrop-filter:blur(37.22630310058594px) ;
        border-top: 1px solid #FFA900;
        border-bottom: 1px solid #FFEDAE;
        border-left: 1px solid #FFEDAE;
        border-right: 1px solid #FFA900;
        
@media screen and (max-width:768px) {
              width: 100%;
              padding: 16px;
 }


.top-part{
    display: flex;
    align-items: center;
    .left-part, .right-part{
        width: 50%;
        text-align: center;

        h2{
            font-size: 24px;
            color:#f6f6f6;
            position: relative;
            @media screen and (max-width:768px) {
                font-size: 18px;
            }
        }

        h6{
            font-size: 14px;
            color:#cecece;
            @media screen and (max-width:768px) {
                font-size: 10px;
            }
        }
    }
    .left-part{
        h2{
            &::after{
                content: '';
                position: absolute;
                right: 0;
                width: 1px;
                height: 60px;
                background-color: #fff;
                opacity: 0.1;
            }
        }
    }
}

.middle-part{
    hr{
        width: 100%;
        height: 1px;
        background: #fff;
        opacity: 0.1;
    }
}
        .bottom-part{
            h6{
                font-size: 14px;
                color:#cecece;
                span{
                    font-size: 24px;
                    color:#f6f6f6;
                    @media screen and (max-width:768px) {
                font-size: 18px;
            }
                }
            }
        }
    }
}

.second-part{
    img{
        width: 100%;
        height: 500px;

        @media screen and (max-width:768px) {
                height: 350px;
            }
    }
}
`