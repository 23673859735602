import styled from "styled-components";


export const TradingContainer = styled.div`
padding:80px 60px 100px 60px;
position: relative;
@media screen and (max-width:768px) {
padding: 0px 30px 50px 30px;
&::before{
    display: none !important;
}
}

&::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 416.799px;
    height: 205.12px;
    transform: rotate(166.24deg);
    border-radius: 416.799px;
    background: #FFA900;
    filter: blur(304.8599853515625px);
    -webkit-filter: blur(304.8599853515625px);
    -moz-filter: blur(304.8599853515625px);
    z-index: -1;
    opacity: 0.6;
    -webkit-transform: translate3d(0,0,0);
}

h2{
    text-align: center;
}

.cards-container{
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:28px;

    @media screen and (max-width:768px) {
        flex-direction: column;
}

.card{
position: relative;
display: flex;
padding: 36px 24px;
flex-direction: column;
align-items: center;
gap: 20px;
border-radius: 16px;
border: 1px solid #FFA900;
background: linear-gradient(137deg, rgba(255, 255, 255, 0.04) -6.35%, rgba(255, 255, 255, 0.02) 105.62%);
backdrop-filter: blur(37.22630310058594px);
-webkit-backdrop-filter: blur(37.22630310058594px);
-moz-backdrop-filter: blur(37.22630310058594px);
border-top: 1px solid #FFA900;
            border-bottom: 1px solid #FFEDAE;
            border-left: 1px solid #FFEDAE;
            border-right: 1px solid #FFA900;

.icon{
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 12px;
background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);

    svg{
        font-size: 20px;
        color:#0a0a0b;
    }
}

h2{
    position: relative;
    color:transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #FFA900;
    font-size: 40px;
    
    &::before{
        position: absolute;
        content: "2";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        color:transparent;
        background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 93.08%);
         -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #FFA900;
             background-clip: text;
            opacity: 0.3;
            filter: blur(30px);
    }
}

h5{
    font-size: 20px;
    text-align: center;
}
h6{
    font-size:16px;
    text-align: center;
}

    }
}

`