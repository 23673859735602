import React from 'react'
import { TradingContainer } from './style'
import Heading from "../../../Components/Headings"
import {FaHandHoldingDollar} from 'react-icons/fa6'
import {FaPlus} from 'react-icons/fa'
import {BiRefresh} from 'react-icons/bi'

const index = () => {
  return (
    <TradingContainer>
    <div className="bg-lines">
      <span className="line-1"></span>
      <span className="line-2"></span>
      <span className="line-3"></span>
      <span className="line-4"></span>
      <span className="line-5"></span>
      </div>
        <Heading text={'NFT Creation & Trading.'} size={'40px'} color={'#fff'}/>
        <div className="cards-container">
            <div className="card">
                <div className="icon">
                    <FaPlus />
                </div>
                <h5>Create your NFTs</h5>
                <h6>Easily mint your NFTs and showcase your digital assets on our user-friendly interface.</h6>
            </div>
            <div className="card">
                <div className="icon">
                    <BiRefresh />
                </div>
                <h5>Trade your NFTs</h5>
                <h6>Buy and sell NFTs with ease, knowing that you can enjoy a seamless, user-friendly interface.</h6>
            </div>
            <div className="card">
                <div className="icon">
                    <FaHandHoldingDollar />
                </div>
                <h5>Earn Royality Income</h5>
                <h6>Creators receive royalty income from secondary sales of their NFTs.</h6>
            </div>
        </div>
    </TradingContainer>
  )
}

export default index