import React, {useState} from 'react'
import { MailContainer } from './style'
import  Heading  from '../../../Components/Headings'
import MailchimpSubscribe from "react-mailchimp-subscribe";

const Index = () => {
  const url =
  "https://ozandac.us11.list-manage.com/subscribe/post?u=f6cb837479e4104a024f29f7b&amp;id=0f6d9a239f&amp;f_id=00c2bce0f0";

const Form = ({ status, message, onValidated }) => {
  let email
  const submit = () =>
  email &&
  email.value.indexOf("@") > -1 &&
  onValidated({
    EMAIL: email.value
  });

 return (
  <>
          {status === "sending" && (
          <div style={{ color: "#ffa900" }}>sending...</div>
        )}
        {status === "error" && (
          <div
            style={{ color: "#ed2626" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "#2cee71" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
 <div className='email-container'>
  <input type="email" placeholder="Enter Your Email"  className='email'  ref={(node) => (email = node)}/>
  <button className='send-btn' onClick={submit}>I'm in</button>
  </div>
  </>
 )
}

  return (
    <MailContainer id='join'>
          <div className="bg-lines">
      <span className="line-1"></span>
      <span className="line-2"></span>
      <span className="line-3"></span>
      <span className="line-4"></span>
      <span className="line-5"></span>
      </div>
        <div className="card" >
        <Heading text={'Never Miss a Drop!'} size={'40px'} color={'#fff'}/>
        <h6>Join our mailing list to stay in the loop with our newest feature releases, NFT drops, and tips and tricks.</h6>
        <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <Form
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />

        </div>
    </MailContainer>
  )
}

export default Index