export const fonts = {
  russo: 'Russo One',
  poppins: 'Poppins',
};

export const colors = {
  primaryText:'#f6f6f6',
  secondaryText: '#cecece',
  gradientText1:'#ffa900',
  gradientText2: '#ffedae',
  darkBG: '#0A0A0B',
  darkBG1:'#17191E',
  darkBorder:'#252A31'
};

