import React from 'react'
import {Heading} from './style'
const index = ({text, size, color}) => {
  return (
    <Heading size={size} color={color} text={text}>
      {text}
    </Heading>
  )
}

export default index