import styled from "styled-components";

export const NewsContainer = styled.div`
padding:80px 0px 100px 60px;
position: relative;

@media screen and (max-width:768px) {
   padding: 0 0px 50px 30px;
}


.heading-container{
    padding-right: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width:768px) {
        padding-right: 20px;
    }
    

.view-btn{
display: flex;
padding: 8px 20px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 12px;
background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
border: none;
color: #0a0a0b;
cursor: pointer;
&:focus{
    outline: none;
}
}
    }

.articles-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:28px;
    margin-top: 36px;
    overflow-x: hidden;
    padding-bottom: 20px;

    &::-webkit-scrollbar {
            height: 6px;
            width: 3px;
            display: none;
          }
          &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background: gainsboro;
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #ffa900;
          }

    article{
        display: flex;
        flex-direction: column;

        @media screen and (max-width:768px) {
            width: 250px;

        }

        h4{
            font-size: 24px;
            margin-top: 20px;
            margin-bottom: 10px;

            @media screen and (max-width:768px){
                font-size: 16px;
            }
        }

        .info-container{
            display: flex;
            justify-content: space-between;
            
            div{
                display: flex;
                gap: 8px;
                color:#cecece;
                @media screen and (max-width:768px) {
                    font-size: 12px;
                    }
                span:first-child{
                    border-right: 1px solid #4B515B;
                    padding-right: 8px;

                }
            }

            a{
                display: flex;
                align-items: center;
                gap:5px;
                background:  linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media screen and (max-width:768px) {
                    font-size: 12px;
                }

                svg{
                    color:#ffa900;
                    font-size: 12px;
                    @media screen and (max-width:768px) {
                    font-size: 10px;
                }
                }
            }
        }
    }
}


`
