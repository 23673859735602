import styled from "styled-components";

export const PopularContainer = styled.div`
padding:80px 0px 100px 60px;
position: relative;
overflow-x: hidden;
@media screen and (max-width:768px) {
padding: 0px 30px 50px 30px;
&::before{
    display: none !important;
}
}

&::before{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 416.799px;
    height: 405.12px;
    transform: rotate(166.24deg);
    border-radius: 416.799px;
    background: #FFA900;
    filter: blur(304.8599853515625px);
    -webkit-filter: blur(304.8599853515625px);
    -moz-filter: blur(304.8599853515625px);
    z-index: -1;
    opacity: 0.6;
    -webkit-transform: translate3d(0,0,0);
}

.heading-container{
    padding-right: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width:768px) {
        padding-right: 0px;
    }

.view-btn{
display: flex;
padding: 8px 20px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 12px;
background:linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
border: none;
cursor: pointer;
&:focus{
    outline: none;
}
}

.button-container{
display: flex;
justify-content: space-between;
align-items: center;
gap:10px;

    .icon{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
background: #101316;
border: none;
cursor: pointer;

&:disabled{
    opacity: 0.5;
}
@media screen and (max-width:768px) {
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 8px;
}


    svg{
        font-size: 10px;
        color:#fff;
        @media screen and (max-width:768px) {
            font-size: 15px;
}
    }
}   
}
}


.img-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  border: 5px solid #ffffff;
  border-radius: 10px;
}



.articles-container{
    display: inline-flex;
    align-items: center;
    width: max-content;
    overflow: hidden;
    // gap:28px;
    margin-top: 36px;
    /* overflow-x: auto; */
    padding-bottom: 20px;
    /* padding-right: 30px; */
    /* padding-left: 30px; */
    /* justify-content: space-between;
    flex-wrap: nowrap; */
    white-space: nowrap;
    // transform: translate(-60px);
    // animation: scroll 30s linear infinite;
    // -webkit-animation: scroll 30s linear infinite;
    // animation-delay: 0ms;
    flex-wrap: nowrap;

  @keyframes scroll { 
    0% {
	transform: translate(-60px);
}
100% {
	transform: translate(-50%);
}
 } 

 &:hover{
    // animation-play-state: paused!important;
}

    @media screen and (max-width:768px) {
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    } 
    
    article{
        display: flex;
        flex-direction: column;
        width: 196px;
        height: 284px;
        border-radius: 16px;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
        position: relative;
        // transform: translate3d(0, 0, 0);
        // margin:0px 28px;
        /* overflow: hidden; */

        img{
          width: 196px;
          height: 60%;
          background-size: contain;
        }

        .info-container{
            width: 100%;
            height: auto;
            position: absolute;
            bottom: -1px;
            left: 0;
            display: flex;
            flex-direction: column;
            background: rgba(13, 13, 13, 0.80);
            backdrop-filter: blur(14px);
            padding:12px;
            gap:10px;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
            .top-part{
            display: flex;
            flex-direction: column;
            gap:4px;
            h5{
                font-size: 12px;
                font-weight: 400;
                color:#cecece;
                display: flex;
                gap:5px;
                align-items: center;
                svg{
                    font-size: 16px;
                    color:#ffa900;
                }
            }

            h4{
                font-size: 14px;
            }
    }
}

.middle-part{
    hr{
        width: 100%;
        height: 1px;
        background: #fff;
        opacity: 0.1;
    }
}
        .bottom-part{
            display: flex;
            align-items: center;
            gap:30px;

            h6{
                font-size: 12px;
                color:#cecece;
            }
            h4{
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
        }

        article{
        display: flex;
        flex-direction: column;
        // width: 196px;
        width: fit-content;
        height: 284px;
        border-radius: 16px;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
        position: relative;
        transform: translate3d(0, 0, 0);
        // margin:0px 28px;
        overflow: hidden;

        img{
          width: fit-content;
          height: 60%;
          background-size: contain;
        }

        .info-container{
            width: 100%;
            height: auto;
            position: absolute;
            bottom: -1px;
            left: 0;
            display: flex;
            flex-direction: column;
            background: rgba(13, 13, 13, 0.80);
            backdrop-filter: blur(14px);
            padding:12px;
            gap:10px;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
            .top-part{
            display: flex;
            flex-direction: column;
            gap:4px;
            h5{
                font-size: 12px;
                font-weight: 400;
                color:#cecece;
                display: flex;
                gap:5px;
                align-items: center;
                svg{
                    font-size: 16px;
                    color:#ffa900;
                }
            }

            h4{
                font-size: 14px;
            }
    }
}

.middle-part{
    hr{
        width: 100%;
        height: 1px;
        background: #fff;
        opacity: 0.1;
    }
}
        .bottom-part{
            display: flex;
            align-items: center;
            gap:30px;

            h6{
                font-size: 12px;
                color:#cecece;
            }
            h4{
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
`

