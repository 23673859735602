import React from 'react'
import Heading from '../../../Components/Headings'
import { NewsContainer } from './style'
import { Article1 } from '../../../Assets'
import { Link } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa6'

const index = () => {
  return (
<NewsContainer>
<div className="bg-lines">
      <span className="line-1"></span>
      <span className="line-2"></span>
      <span className="line-3"></span>
      <span className="line-4"></span>
      <span className="line-5"></span>
      </div>
    <div className="heading-container">
        <Heading text={'Latest News.'} size={'40px'} color={'#fff'}/>
        <button className='view-btn'>View All</button>
    </div>
    <div className="articles-container">
        <article>
            <img src={Article1} alt="" />
            <h4>Oriental Dwarf Kingfisher</h4>
            <div className="info-container">
                <div>
                <span>Aug 12, 2023</span>
                <span>5 min read</span>
                </div>
                <Link to="/">Read More <FaChevronRight /></Link>
            </div>
        </article>
        <article>
            <img src={Article1} alt="" />
            <h4>Oriental Dwarf Kingfisher</h4>
            <div className="info-container">
                <div>
                <span>Aug 12, 2023</span>
                <span>5 min read</span>
                </div>
                <Link to="/">Read More <FaChevronRight /></Link>
            </div>
        </article>
        <article>
            <img src={Article1} alt="" />
            <h4>Oriental Dwarf Kingfisher</h4>
            <div className="info-container">
                <div>
                <span>Aug 12, 2023</span>
                <span>5 min read</span>
                </div>
                <Link to="/">Read More <FaChevronRight /></Link>
            </div>
        </article>
        <article>
            <img src={Article1} alt="" />
            <h4>Oriental Dwarf Kingfisher</h4>
            <div className="info-container">
                <div>
                <span>Aug 12, 2023</span>
                <span>5 min read</span>
                </div>
                <Link to="/">Read More <FaChevronRight /></Link>
            </div>
        </article>
    </div>
</NewsContainer>
  )
}

export default index