import React, {useState, useRef, useEffect} from 'react'
import Heading from '../../../Components/Headings'
import { PopularContainer } from './style'
import { NFT, NFT1, NFT2, NFT3, NFT4, NFT5, NFT6, NFT7, NFT8, NFT9, NFT10, NFT11, NFT12,NFT13,NFT14,NFT15,NFT16,NFT17,NFT18,NFT19,NFT20 } from '../../../Assets'
import { Link } from 'react-router-dom'
import {BiSolidBadgeCheck} from 'react-icons/bi'
import Swiper from 'swiper';
import { Autoplay, FreeMode } from 'swiper/modules';


const HorizontalSlider = () => {
  const swiperParams = {
    slidesPerView: "auto",
    spaceBetween: 56,
    pagination: {
      clickable: false,
    },
    loop: true,
    autoplay: {
      delay: 1500,
      pauseOnMouseEnter: true
    },
    freeMode: true
  };
  useEffect(() => {
    const swiper = new Swiper('#popularSlider', {
      // configure Swiper to use modules
      modules: [FreeMode, Autoplay],
      ...swiperParams
    });

  }, []);

  

  return (
    <div id="popularSlider">
    <div className='articles-container swiper-wrapper'>
     {
      NFTs.map((item, index) => {
        return <article className='swiper-slide' key={index}>
        <img src={item.img} alt=""/>
        <div className="info-container">
        <div className="top-part">
        <h5>{item.title.substring(0, 21)} <BiSolidBadgeCheck /> </h5>
        <h4>{item.owner}</h4>
        </div>
        <div className="middle-part">
            <hr/>
        </div>
        <div className="bottom-part">
        <div>
            <h5>{item.type === 'NFT' ? 'Highest Bid' : 'Volume'}</h5>
            <h4>{item.type === 'NFT' ? item.highest_bid : item.volume}</h4>
        </div>
        <div>
            <h5>{item.type === 'NFT' ? 'Price' : 'Floor Price'}</h5>
            <h4>{item.type === 'NFT' ? item.price : item.floor_price}</h4>
        </div>
        </div>
        </div> 
    </article>
      })
      }
</div>
    </div>
  );
};


const NFTs = [
  {
    type:'NFT',
    img:NFT1,
    title:'Ninja Squad Official',
    owner:'Ninja #5455',
    highest_bid:'0.0551 ETH',
    price:'0.165 ETH'
  },
  {
    type:'NFT',
    img:NFT2,
    title:"Lil' Heroes by Edgar Plans",
    owner:'#2696',
    highest_bid:'0.048 ETH',
    price:'2.19 ETH'
  },
  {
    type:'NFT',
    img:NFT3,
    title:'Doodles',
    owner:'Doodle #2493',
    highest_bid:'5.25 ETH',
    price:'4.82 ETH'
  },
  {
    type:'NFT',
    img:NFT4,
    title:'Bored Ape Yacht Club',
    owner:'#6640',
    highest_bid:'23.5325 ETH',
    price:'1.14 ETH'
  },
  {
    type:'NFT',
    img:NFT5,
    title:'Bored Ape Kennel Club',
    owner:'#2263',
    highest_bid:'1.5302 ETH',
    price:'0.888 ETH'
  },
  {
    type:'NFT',
    img:NFT6,
    title:'Bored Ape Yacht Club',
    owner:'#188',
    highest_bid:'23.3708 ETH',
    price:'82 ETH'
  },
  {
      type:'NFT',
    img:NFT7,
    title:'Bored Ape Yacht Club',
    owner:'#100',
    highest_bid:'23.7 ETH',
    price:'27 ETH'
  },
  {
    type:'NFT',
    img:NFT8,
    title:'Mutant Ape Yacht Club',
    owner:'#20292',
    highest_bid:'4.7747 ETH',
    price:'16.88 ETH'
  },
  {
      type:'NFT',
    img:NFT9,
    title:'Mutant Ape Yacht Club',
    owner:'#1830',
    highest_bid:'4.649 ETH',
    price:'15.99 ETH'
  },
  {
    type:'NFT',
    img:NFT10,
    title:'Bored Ape Yacht Club',
    owner:'#3976',
    highest_bid:'24.5 ETH',
    price:'105 ETH'
  },  
  {
    type:'collections',
    img:NFT11,
    title:'Sugartown Oras',
    owner:'Zynga',
    highest_bid:'',
    price:'',
    floor_price:'0.41 ETH',
    volume:'303 ETH'
  }, 
   {
    type:'collections',
    img:NFT12,
    title:'Trump Digital Trading Cards Series 2',
    owner:'TrumpDeployer',
    highest_bid:'',
    price:'',
    floor_price:'174 MATIC',
    volume:'16 ETH'
  },  
  {
    type:'collections',
    img:NFT13,
    title:'Parallel Alpha',
    owner:'Parallel',
    highest_bid:'',
    price:'',
    floor_price:'0.15 PRIME',
    volume:'13 ETH'
  },  
  {
    type:'collections',
    img:NFT14,
    title:'10KTF G Tags',
    owner:'WagmiSan',
    highest_bid:'',
    price:'',
    floor_price:'0.37 ETH',
    volume:'76 ETH'
  },  
  {
    type:'collections',
    img:NFT15,
    title:'Nakamigos',
    owner:'Nakamigos-Deployer',
    highest_bid:'',
    price:'',
    floor_price:'0.28 ETH',
    volume:'57 ETH'
  },  
  {
    type:'collections',
    img:NFT16,
    title:'Opepen Edition',
    owner:'visualizevalue',
    highest_bid:'',
    price:'',
    floor_price:'0.41 ETH',
    volume:'50 ETH'
  },  
  {
    type:'collections',
    img:NFT17,
    title:'Mutant Ape Yacht Club',
    owner:'YugaLabs',
    highest_bid:'',
    price:'',
    floor_price:'4.95 ETH',
    volume:'445 ETH'
  },  
  {
    type:'collections',
    img:NFT18,
    title:'Ordinal Kubz',
    owner:'Keungz',
    highest_bid:'',
    price:'',
    floor_price:'0.08 ETH',
    volume:'6 ETH'
  },  
  {
    type:'collections',
    img:NFT19,
    title:'Moonbirds',
    owner:'70C71B',
    highest_bid:'',
    price:'',
    floor_price:'1.31 ETH',
    volume:'87 ETH'
  },  
  {
    type:'collections',
    img:NFT20,
    title:'Azuki Elementals',
    owner:'TeamAzuki',
    highest_bid:'',
    price:'',
    floor_price:'0.45 ETH',
    volume:'34 ETH'
  },  
]


const Index = () => {
  return (
<PopularContainer>
<div className="bg-lines">
      <span className="line-1"></span>
      <span className="line-2"></span>
      <span className="line-3"></span>
      <span className="line-4"></span>
      <span className="line-5"></span>
      </div>
    <div className="heading-container">
        <Heading text={'Popular NFTs.'} size={'40px'} color={'#fff'}/>
    </div>    
    <HorizontalSlider />
</PopularContainer>
  )
}

export default Index