import React from "react";
import {
  FooterContainer,
  FooterTopContent,
  FooterBottomContent,
} from "./style";
import {
  FooterLogo,
} from "../../Assets";
import { useNavigate, Link } from "react-router-dom";
import {MdOutlineKeyboardArrowRight} from 'react-icons/md';
import {FaTwitter, FaInstagram, FaDiscord, FaTelegram} from 'react-icons/fa';
import MailchimpSubscribe from "react-mailchimp-subscribe";
const Footer = () => {
  const url =
  "https://ozandac.us11.list-manage.com/subscribe/post?u=f6cb837479e4104a024f29f7b&amp;id=0f6d9a239f&amp;f_id=00c2bce0f0";

const Form = ({ status, message, onValidated }) => {
  let email
  const submit = () =>
  email &&
  email.value.indexOf("@") > -1 &&
  onValidated({
    EMAIL: email.value
  });

 return (
  <>
          {status === "sending" && (
          <div style={{ color: "#ffa900",  textAlign:'center'  }}>sending...</div>
        )}
        {status === "error" && (
          <div
            style={{ color: "#ed2626", textAlign:'center' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "#2cee71",  textAlign:'center'  }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <div className="footer-contants">
          <div className="email-box">
          <input type="email" placeholder="Enter your email" className="email"  ref={(node) => (email = node)} />
          <button className="send-btn" onClick={submit}><MdOutlineKeyboardArrowRight/></button>
          </div>
        </div>
        </>
 )
}


  return (
    <FooterContainer>
      <FooterTopContent>
        <div className="footer-logo">
          <img src={FooterLogo} alt="" />
        </div>
        <div className="footer-container">
        <div>
          <h4>Never miss a drop!</h4>
          <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <Form
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
        </div>
        <div>
          <h4>Join the Community.</h4>
        <div className="footer-contants">
          <Link className="social-icons" to="https://twitter.com/warriorx_io"><FaTwitter /></Link>
          {/* <button className="social-icons"><FaInstagram /></button> */}
          {/* <button className="social-icons"><FaTelegram /></button> */}
          <Link className="social-icons" to="https://discord.gg/FTuhF6A2"><FaDiscord /></Link>
        </div>
        </div>
        <div className="footer-client">
        <Link to="/">
            Home
          </Link>
          <Link to="/">
            Articles
          </Link>
          <Link to="/">
            Market Place <span style={{color:'#252a31', padding:'2px 8px', background:'linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%)', borderRadius:'8px', marginLeft:'5px'}}>Soon</span>
          </Link>
        </div>
        </div>
      </FooterTopContent>
      <FooterBottomContent>
        <div className="brand-icon">
          <Link to="/">
            Privacy Policy
          </Link>
          <Link to="/">
            Terms of Service
          </Link>
          <Link to="/">
            Legal Notice
          </Link>
        </div>
        <div className="brand-brand-name">
          © {new Date().getFullYear()} &nbsp;
            WARRIOR. All rights reserved.
        </div>
      </FooterBottomContent>
    </FooterContainer>
  );
};

export default Footer;
