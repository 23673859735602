import React from 'react'
import { TradingContainer } from './style'
import Heading from "../../../Components/Headings"
import {FaCheck} from 'react-icons/fa'
import { GasFee } from '../../../Assets'

const index = () => {
  return (
    <TradingContainer>
            <div className="bg-lines">
      <span className="line-1"></span>
      <span className="line-2"></span>
      <span className="line-3"></span>
      <span className="line-4"></span>
      <span className="line-5"></span>
      </div>
        <Heading text={'Gas Fee Optimization.'} size={'40px'} color={'#fff'}/>
        <div className="cards-container">
            <img src={GasFee} alt="" />
            <div className="cards">
            <div className="card">
                <div className="icon">
                    <FaCheck />
                </div>
                <div>
                <h5>Off Chain Orders</h5>
                <h6>Place orders without paying substantial gas fees. Gas fees are only charged upon order cancellation or execution.</h6>
                </div>
            </div>
            <div className="card">
                <div className="icon">
                    <FaCheck />
                </div>
                <div>
                <h5>Third-Party NFTs</h5>
                <h6>WARRIOR supports third-party NFTs, allowing you to transfer and trade NFTs created elsewhere. This means you can still benefit from our optimization features without having to mint new NFTs.</h6>
                </div>
            </div>
            </div>
        </div>
    </TradingContainer>
  )
}

export default index