import React from 'react'
import { HeaderContainer } from './style'
import { HeaderImage } from '../../../Assets'

const index = () => {
  return (
    <HeaderContainer>
        <div className="first-part">
        <h1>Welcome to <br /><span>WARRIOR</span>.</h1>
        <p>A decentralized NFT trading platform that offers convenience, flexibility, and security. Our platform supports multiple tokens and chains, allowing users to create, showcase, and trade their NFTs.</p>
        <div className="stat-card">
            <div className="top-part">
                <div className="left-part">
                    <h2>$26.98 Billion</h2>
                    <h6>NFT market size</h6>
                </div>
                <div className="right-part">
                <h2>$1.8 Billion</h2>
                <h6>NFT sales amount every month</h6>
                </div>
            </div>
            <div className='middle-part'>
                <hr />
            </div>
            <div className="bottom-part">
                <h6>The NFT Market will be worth <span>$211.72 billion</span> by 2030</h6>
            </div>
        </div>
        </div>
        <div className="second-part">
        <img src={HeaderImage} alt="" />
        </div>
    </HeaderContainer>
  )
}

export default index