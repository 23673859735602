import React, {useState} from 'react'
import { NavbarContainer, NavbarContent, MobileNavbarContent, MobileNavbarContainer } from './style'
import { Link } from 'react-router-dom'
import {Logo, Britain} from "../../Assets"
import {FaTwitter, FaInstagram, FaDiscord, FaTelegram, FaBars, FaTimes} from 'react-icons/fa';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false)
  
  return (
    <>
   <NavbarContainer>
      <NavbarContent>
        <div className='first-part'>
        <div className="navbar-logo">
          <img src={Logo} alt="" />
        </div>
        <div className="brand-icon">
        <Link to="/" className='active'>
            Home
          </Link>
          <Link to="/">
            Articles
          </Link>
          <Link to="/">
            Market Place <span style={{color:'#252a31', padding:'2px 8px', background:'linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%)', borderRadius:'8px', marginLeft:'5px'}}>Soon</span>
          </Link>
        </div>
        </div>
        <div className='second-part'>
          <a href="#join" className='join-btn'>Join</a>
          <div className="navbar-constants">
          <Link className="social-icons" to="https://twitter.com/warriorx_io"><FaTwitter /></Link>
          {/* <button className="social-icons"><FaInstagram /></button> */}
          {/* <button className="social-icons"><FaTelegram /></button> */}
          <Link className="social-icons" to="https://discord.gg/FTuhF6A2"><FaDiscord /></Link>
        </div>
        <button className='flag-btn'><img src={Britain} alt="" /></button>
        </div>
      </NavbarContent>
      <MobileNavbarContent active={isOpen}>
      <div className="navbar-logo">
          <img src={Logo} alt="" />
        </div>
        <FaBars onClick={() => setIsOpen(!isOpen)} />
      </MobileNavbarContent>
   </NavbarContainer>
     <MobileNavbarContainer active={isOpen}>
      <div className='header-mobile'>
         <div className="navbar-logo">
             <img src={Logo} alt="" />
           </div>
           <FaTimes onClick={() => setIsOpen(false)} />
           </div>
           <div className="mobile-content">
           <div className="brand-icon">
        <Link to="/" className='active'>
            Home
          </Link>
          <Link to="/">
            Articles
          </Link>
          <Link to="/">
            Market Place <span style={{color:'#252a31', padding:'2px 8px', background:'linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%)', borderRadius:'8px', marginLeft:'5px'}}>Soon</span>
          </Link>
        </div>
        <div className='second-part'>
          <Link to="#join" className='join-btn'>Join</Link>
          <div className="navbar-constants">
          <Link className="social-icons" to="https://twitter.com/warriorx_io"><FaTwitter /></Link>
          {/* <button className="social-icons"><FaInstagram /></button>
          <button className="social-icons"><FaTelegram /></button> */}
          <Link className="social-icons" to="https://discord.gg/FTuhF6A2"><FaDiscord /></Link>
        </div>
        <button className='flag-btn'><img src={Britain} alt="" /></button>
        </div>
           </div>
         </MobileNavbarContainer>
         </>
  )
}

export default NavBar