import React, {useEffect} from 'react'
import Heading from '../../../Components/Headings'
import { PopularContainer } from './style'
import {Mint1, Mint2, Mint3, Mint4, Mint5, Mint6, Mint7, Mint8, Mint9, Mint10} from '../../../Assets/NFT/mint'
import { Link } from 'react-router-dom'
import {BiSolidBadgeCheck} from 'react-icons/bi'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa6'
import Swiper from 'swiper';
import { Autoplay, FreeMode } from 'swiper/modules';

const NFTs = [
  {
    type:'NFT',
    img:Mint1,
    title:'Etheral Legends',
    owner:'Ethereal White Serpent',
    highest_bid:'0.0551 ETH',
    price:'0.165 ETH'
  },
  {
    type:'NFT',
    img:Mint2,
    title:"Temporal Adventures",
    owner:'Steampunk Mario Ody...',
    highest_bid:'800 ETH',
    price:'1.5 ETH'
  },
  {
    type:'NFT',
    img:Mint3,
    title:'Mountain Mysteries',
    owner:'Enchantress of the Hig...',
    highest_bid:'950 ETH',
    price:'2.2 ETH'
  },
  {
    type:'NFT',
    img:Mint4,
    title:'Alchemy Instruments',
    owner:'Fog Alchemist Vial #29',
    highest_bid:'620 ETH',
    price:'1.3 ETH'
  },
  {
    type:'NFT',
    img:Mint5,
    title:'Metallic Manifestations',
    owner:'Liquid Luminescence...',
    highest_bid:'890 ETH',
    price:'1.9 ETH'
  },
  {
    type:'NFT',
    img:Mint6,
    title:'Desert Dreams',
    owner:'Desert Luminous Orb #31',
    highest_bid:'710 ETH',
    price:'1.4 ETH'
  },
  {
    type:'NFT',
    img:Mint1,
    title:'Etheral Legends',
    owner:'Ethereal White Serpent',
    highest_bid:'0.0551 ETH',
    price:'0.165 ETH'
  },
  {
    type:'NFT',
    img:Mint2,
    title:"Temporal Adventures",
    owner:'Steampunk Mario Ody...',
    highest_bid:'800 ETH',
    price:'1.5 ETH'
  },
  {
      type:'NFT',
    img:Mint7,
    title:'Retro-Futuristic Chronicl...',
    owner:'90s Mech Maiden #32',
    highest_bid:'830 ETH',
    price:'1.6 ETH'
  },
  {
    type:'NFT',
    img:Mint8,
    title:'Enchanted Visions',
    owner:'Dreamscape Deluge #33',
    highest_bid:'680 ETH',
    price:'1.5 ETH'
  },
  {
      type:'NFT',
    img:Mint9,
    title:"Nature's Secrets",
    owner:'X-rayed Fishbone Elegance #34',
    highest_bid:'540 ETH',
    price:'1.1 ETH'
  },
  {
    type:'NFT',
    img:Mint10,
    title:'Mystic Moments',
    owner:'Bathhouse Reverie #35',
    highest_bid:'860 ETH',
    price:'1.8 ETH'
  }, 
  {
    type:'NFT',
    img:Mint5,
    title:'Metallic Manifestations',
    owner:'Liquid Luminescence...',
    highest_bid:'890 ETH',
    price:'1.9 ETH'
  }, 
]

const HorizontalSlider = () => {
  
  const swiperParams = {
    
    slidesPerView: "auto",
    spaceBetween: 56,
    pagination: {
      clickable: false,
    },
    autoplay: {
      delay: 2000,
      pauseOnMouseEnter: true
    },
    freeMode: true,
    loop: true,
  };
  useEffect(() => {
    const swiperEx = new Swiper('#exclusiveSlider', {
      // configure Swiper to use modules
      modules: [FreeMode, Autoplay],
      ...swiperParams
    });
  //   swiperEx.on('reachEnd', function () {
  //     swiperEx.slideTo(0, 1000, true);
  //     swiperEx.autoplay.resume();
  // });
  }, []);


  return (
    <div id="exclusiveSlider">
    <div className='articles-container swiper-wrapper'>
        
     {
      NFTs.map((item, index) => {
        return <article className="swiper-slide" key={index}>
        <img src={item.img} alt="" />
        <div className="info-container">
        <div className="top-part">
        <h5>{item.title.substring(0, 21)} <BiSolidBadgeCheck /> </h5>
        <h4>{item.owner}</h4>
        </div>
        <div className="middle-part">
            <hr/>
        </div>
        <div className="bottom-part">
        <div>
            <h5>{item.type === 'NFT' ? 'Highest Bid' : 'Volume'}</h5>
            <h4>{item.type === 'NFT' ? item.highest_bid : item.volume}</h4>
        </div>
        <div>
            <h5>{item.type === 'NFT' ? 'Price' : 'Floor Price'}</h5>
            <h4>{item.type === 'NFT' ? item.price : item.floor_price}</h4>
        </div>
        </div>
        </div> 
    </article>
      })
      }
</div>
    </div>
  );
};

const Index = () => {
  return (
<PopularContainer>
<div className="bg-lines">
      <span className="line-1"></span>
      <span className="line-2"></span>
      <span className="line-3"></span>
      <span className="line-4"></span>
      <span className="line-5"></span>
      </div>
    <div className="heading-container">
        <Heading text={'Minted Exclusively on WARRIOR.'} size={'40px'} color={'#fff'}/>
    </div>
    <HorizontalSlider />
</PopularContainer>
  )
}

export default Index