import styled from "styled-components";

export const Heading = styled.h2`
      position: relative;
        font-size: ${(props) =>
        props.size ? props.size : "24px"};
        font-family: 'Russo One';
        background: linear-gradient(128deg, #FFA900 23.44%, #FFEDAE 71.33%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media screen and (max-width:768px) {
                font-size: 24px;
            }
       &::after {
         content: "${(props) => props.text ? props.text : "24px"}";
            position: absolute;
            width: 100%;
            height: 100%;
            color: #F6f6f6;
            top: 0;
            left: 0;     
            opacity: 0.3;
            filter: blur(9px);     
            background: linear-gradient(128deg, #FFA900 23.44%, #FFEDAE 71.33%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
        }
`
