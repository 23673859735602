import styled from "styled-components";
import {colors} from "../../theme"

export const NavbarContainer = styled.nav`
  position: relative;
  z-index: 99;
  position: fixed;
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 72px;
box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
background: rgba(10, 10, 11, 0.60);
backdrop-filter: blur(5px);

@media screen and (max-width:768px) {
  height: 52px;
}

`;

export const MobileNavbarContent = styled.div`
height: 52px;
align-items: center;
justify-content: space-between;
padding: 0 30px;
display: none;

@media screen and (max-width:768px) {
  display: flex;
}

svg{
  font-size: 24px;
  cursor: pointer;
}
`

export const MobileNavbarContainer = styled.div`
height: 100vh;
width: 100vw;
display: flex;
flex-direction: column;
gap: 30px;
padding: 24px 30px;
background-color: #000;
position: fixed;
top: 0;
left: ${props => props.active ? '0' : '-100%'};
right: 0;
bottom: 0;
z-index: 999;
overflow-y: hidden;
box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.28);
background: rgba(10, 10, 11, 0.80);
backdrop-filter: blur(10px);
transition: 0.6s ease all;

.header-mobile{
  display: flex;
justify-content: space-between;
align-items: center;
  svg{
  font-size: 24px;
  cursor: pointer;
}
}

.mobile-content{
  .brand-icon {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 22px;
      a{
        color: #f6f6f6;
        position: relative;
      }
      .active{
        &:after{
          position: absolute;
          content: "Home";
          font-size: 16px;
          font-weight: 600;
          font-family: 'Poppins', sans-serif;
          top: 0;
          left: 0;
          color: #F6f6f6;
          filter: blur(6px);
        }
      }
    }
    .second-part{
      margin-top: 30px;
    .join-btn{
      font-size: 16px;
      background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
        background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
      border: none;
      &:focus{
        outline: none;
      }
    }
    .navbar-constants {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    @media (max-width: 768px) {
      align-items: center;
    }
    @media (max-width: 1000px) {
      align-items: center;
    }
    .social-icons{
   display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 12px;
  background-color: #101316;
  border: none;
  cursor: pointer;
  &:focus{
    outline: none;
  }

  svg{
    font-size: 20px;
    color:#fff
  }
}
}
.flag-btn{
  margin-top: 30px;
  background: none;
  border: none;
  cursor: pointer;
  &:focus{
    outline: none;
  }
}
  }

}


`





export const NavbarContent = styled.div`
  padding: 24px 50px;
  height: 72px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 24px 50px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 1000px) {
    padding: 24px 50px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    display: none;
  }

  .first-part{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:40px;
    .navbar-logo {
      display: flex;
      align-items: center;
      img {
        height: 32px;
        width: 70px;
      }
    }
  
    .brand-icon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 32px;
      a{
        color: #f6f6f6;
        position: relative;
      }
      .active{
        &:after{
          position: absolute;
          content: "Home";
          font-size: 16px;
          font-weight: 600;
          font-family: 'Poppins', sans-serif;
          top: 0;
          left: 0;
          color: #F6f6f6;
          filter: blur(6px);
        }
      }
    }
  }

  .second-part{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:40px;

    .join-btn{
      font-size: 16px;
      background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
        background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
      border: none;
      &:focus{
        outline: none;
      }
    }
    .navbar-constants {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    @media (max-width: 768px) {
      align-items: center;
    }
    @media (max-width: 1000px) {
      align-items: center;
    }
    .social-icons{
   display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 12px;
  background-color: #101316;
  border: none;
  cursor: pointer;
  &:focus{
    outline: none;
  }

  svg{
    font-size: 20px;
    color:#fff
  }
}
}
.flag-btn{
  background: none;
  border: none;
  cursor: pointer;
  &:focus{
    outline: none;
  }
}
  }

`;

