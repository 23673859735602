import styled from "styled-components";
import {colors} from "../../theme"

export const FooterContainer = styled.footer`
  position: relative;
  z-index: 10;
`;

export const FooterTopContent = styled.div`
  padding: 42px 50px;
  background-color: ${colors.darkBG1};
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 768px) {
    padding: 64px 50px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 1000px) {
    padding: 34px 30px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer-logo {
    img {
      height: 32px;
      width: 70px;
    }
  }

  .footer-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;

    h4{
      text-align: center;
    }
  }

  .footer-contants {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 16px;
    margin-top: 10px;
    @media (max-width: 768px) {
      align-items: center;
    }
    @media (max-width: 1000px) {
      align-items: center;
    }

    .email-box{
      position: relative;
.email{
 width: 308px;
height: 40px;
padding: 8px 12px 8px 16px;
justify-content: space-between;
align-items: center;
border-radius: 12px;
border: 1px solid ${colors.darkBorder};
background: #101316;
backdrop-filter: blur(5px);
color:#fff;

::placeholder {
  color:#fff;
}

&:focus{
  outline: none;
}
  }
}

  .send-btn{
    position: absolute;
    right: 5px;
    top: 35%;
    transform: translate(-35%, -5px);
    font-size: 22px;
    background: transparent;
    border: none;
    cursor: pointer;
    &:focus{
      outline: none;
    }

    svg{
      font-size: 22px;
      color:#fff;
    }
  }

.social-icons{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 12px;
  background-color: #101316;
  border: none;
  cursor: pointer;
  &:focus{
    outline: none;
  }

  svg{
    font-size: 20px;
    color:#fff
  }
}


}
  .footer-client {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    @media (max-width: 768px) {
      text-align: center;
    }
    @media (max-width: 1000px) {
      text-align: center;
      gap: 30px;
    }
    a{
      color: ${colors.primaryText}
    }
  }
}
`;

export const FooterBottomContent = styled.div`
  padding: 24px 50px;
  height: 72px;
  background-color: ${colors.darkBG};
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 24px 50px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 1000px) {
    padding: 24px 30px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: auto;
  }
  .brand-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    @media screen and (max-width:768px) {
      gap: 20px;
    }
    a{
      color: #cecece;
      @media screen and (max-width:768px) {
          font-size: 14px;
      }
    }
  }

`;
