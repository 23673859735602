import styled from "styled-components";
import { BgWor } from "../../../Assets";


export const MailContainer = styled.div`
padding:80px 60px 100px 60px;
background-image: url(${BgWor});
display: flex;
justify-content: center;
align-items: center;
position: relative;

@media screen and (max-width:768px) {
padding: 80px 30px 50px 30px;
}



.card{
position: relative;
padding: 36px 44px;
border-radius: 16px;
border: 1px solid #FFA900;
background: linear-gradient(137deg, rgba(255, 255, 255, 0.04) -6.35%, rgba(255, 255, 255, 0.02) 105.62%);
backdrop-filter: blur(37.22630310058594px);
-webkit-backdrop-filter: blur(37.22630310058594px);
display: flex;
flex-direction: column;
align-items: center;
gap: 32px;


@media screen and (max-width:768px){
    padding: 30px 20px;
}


h6{
    font-size: 16px;
    text-align: center;
    width: 70%;

    @media screen and (max-width:768px) {
        width: 100%;
        font-size: 14px;
    }
}

.email-container{
    display: flex;
    gap:10px;
    align-items: center;
    .email{
    width: 315px;
height: 40px;
padding: 8px 12px 8px 16px;
justify-content: space-between;
align-items: center;
border-radius: 12px;
border: 1px solid #252A31;
background: #101316;
backdrop-filter: blur(5px);
color:#f6f6f6;

@media screen and (max-width:768px) {
    width: 250px;
}

&:focus{
    outline: none;
}

&::placeholder{
    color:  #f6f6f6;
}
}
.send-btn{
    display: flex;
padding: 8px 20px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 12px;
background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
border: none;
cursor: pointer;
color: #0a0a0b;
@media screen and (max-width:768px){
    padding: 10px 10px;
    border-radius: 8px;
}

&:focus{
    outline: none;
}
}
}

}

`