import styled from "styled-components";

export const RoadmapContainer = styled.div`
padding: 80px 60px 80px 60px;
position: relative;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

@media screen and (max-width:768px) {
    padding: 40px 30px 40px 30px;
}


h2{
    display: none;

    @media screen and (max-width:768px) {
        display: block;
        margin-bottom: 50px;
    }
}

.mobile-image{
    display: none;
}

@media screen and (max-width:768px) {
    .desktop-image{
        display: none;
    }

    .mobile-image{
        display: block;
    }
}


`